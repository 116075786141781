<script lang="ts" setup>
	import routes from "~/utils/routes";
	const route = useRoute();
	import { useUser } from "~/stores/user";

	const { id } = useUser();

	const { y } = useWindowScroll();
	const shadowShow = ref(false);
	watch(
		y,
		() => {
			process();
		},
		{ immediate: true }
	);

	function process() {
		if (y.value > 10) {
			shadowShow.value = true;
		} else {
			shadowShow.value = false;
		}
	}

	onMounted(() => {
		process();
	});
</script>

<template>
	<header class="flex-vertical">
		<nav class="flex-horizontal">
			<ul class="flex-horizontal">
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.posts.index + '?is_reviewed=true&is_deleted=false'" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.posts.index) }]">
						<span>Posts</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.postResponses.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.postResponses.index) }]">
						<span>Responses</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.postLikes.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.postLikes.index) }]">
						<span>Likes</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.postShares.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.postShares.index) }]">
						<span>Shares</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.postViews.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.postViews.index) }]">
						<span>Views</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.users.index + '?role=owner'" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.users.index) }]">
						<span>Users</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.tokens.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.tokens.index) }]">
						<span>Tokens</span>
					</nuxt-link>
				</li>
				<li class="flex-vertical">
					<nuxt-link :to="routes.admin.categories.index" class="flex-vertical" :class="[{ active: route.path.includes(routes.admin.categories.index) }]">
						<span>Categories</span>
					</nuxt-link>
				</li>
			</ul>

			<Logo color="black" />

			<NuxtLink v-if="id" :to="routes.admin.users.edit.replace(':id', id)" class="profile flex-horizontal">
				<RecordUser :id="id" v-slot="{ record }">
					<p>{{ record?.first_name }} {{ record?.last_name }}</p>
					<profile-picture :id="id" size="small" />
				</RecordUser>
			</NuxtLink>
		</nav>

		<div class="shadow" :class="[{ show: shadowShow }]"></div>
	</header>
</template>

<style scoped lang="scss">
	header {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 3;
		width: 100%;
		pointer-events: none;
		z-index: 1000;

		.shadow {
			display: flex;
			width: 100%;
			height: 30px;
			background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.4s ease-out;
		}

		.shadow.show {
			opacity: 0.02;
		}

		nav {
			pointer-events: all;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			height: 70px;
			padding-left: 40px;
			padding-right: 40px;
			background: white;
			border-bottom: 1px solid #eaeaea;
			position: relative;

			.logo {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			.profile {
				margin-right: -7px;
				padding-left: 14px;
				padding-right: 14px;
				align-items: center;
				justify-content: center;
				height: 100%;
				transition: all 0.2s ease-out;
				gap: 10px;
				p {
					color: var(--black);
				}
			}

			.profile:hover {
				box-shadow: inset 0px -2px 0px var(--beige-dark);
			}
			.profile.router-link-active {
				box-shadow: inset 0px -2px 0px var(--beige-dark);
			}

			ul {
				height: 100%;
				list-style: none;
				margin-left: -7px;
				li {
					height: 100%;
					a {
						height: 100%;
						align-items: center;
						justify-content: center;
						padding-left: 14px;
						padding-right: 14px;
						transition: all 0.2s ease-out;
						/* p {
						color: var(--black);
					} */
					}

					a:hover {
						box-shadow: inset 0px -2px 0px var(--beige-dark);
					}
					a.router-link-active,
					a.active {
						box-shadow: inset 0px -2px 0px var(--black);
						span {
							color: var(--black);
						}
					}
				}
			}
		}
	}
</style>
