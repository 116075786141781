export default function () {
	if (process.client) {
		const el = ref<HTMLBodyElement | null>(document.querySelector("body"));
		const isLocked = useScrollLock(el);

		onMounted(() => {
			isLocked.value = true;
		});

		onUnmounted(() => {
			isLocked.value = false;
		});
	}
}
