<script lang="ts" setup>
	import { SpeedInsights } from "@vercel/speed-insights/vue";

	useHead({
		titleTemplate: (input) => {
			return input ? `${input} - Admin - Mimicmo` : "Admin - Mimicmo";
		},
	});
</script>

<template>
	<SpeedInsights />

	<AdminModalVideoPlayer />

	<AdminHeader />
	<main class="admin">
		<slot />
	</main>
</template>

<style scoped lang="scss">
	main.admin {
		padding-top: 112px;
		padding-bottom: 60px;
	}
</style>
