<script lang="ts" setup>
	const route = useRoute();
	const router = useRouter();
	import UseBodyLock from "../UseBodyLock.vue";

	const id = computed(() => {
		return route.query.post ? route.query.post.toString() : undefined;
	});

	function close() {
		const query = { ...route.query };
		delete query.post;

		router.push({ query: query });
	}
</script>

<template>
	<div class="modal-video-player" v-if="id">
		<UseBodyLock>
			<div class="background" @click="close"></div>

			<RecordPost v-if="id" :id="id" v-slot="{ record }">
				<video-player v-if="record" :value="record" :autoplay="true" />
			</RecordPost>
		</UseBodyLock>
	</div>
</template>

<style scoped lang="scss">
	.modal-video-player {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		z-index: 10000;
		display: flex;
		padding: 40px;

		.background {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			top: 0;
			left: 0;
		}

		.video-player {
			height: 100%;
			width: auto;
			background: black;
		}
	}
</style>
